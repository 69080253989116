<template>
    <div class="mb-3 card">
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6">
                    <v-autocomplete :items="items" :label="$t('erp.lang_items')" return-object item-value="id" item-text="name"
                        :loading="loadingItems" @update:search-input="searchItems" outlined clearable dense
                        @click:clear="clearData"
                        :filter="itemsFilter" v-model="item" hide-details>
                        <template v-slot:item="{ item }">
                            <strong>{{ item.name }}</strong>
                            <span>({{ item.ean }})</span>
                        </template>
                        <template v-slot:no-data>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t("generic.lang_noDataFound") }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <BaseDateInput v-model="date" :disabled="loading" :label="$t('generic.lang_date')" clearable @click:clear="stockData=[]"
                        :loading="loading" :rules="[v => !!v]" dense outlined type="date"  hide-details/>
                </v-col>
                <v-col cols="12">
                    <v-btn block class="mx-auto elevation-0" color="primary" @click="checkStock" :disabled="!item || !date ">
                        {{ $t("generic.lang_check") }}
                    </v-btn>
                </v-col>
                <v-col cols="12" v-if="stockData && stockData.length>0 && item">
                    <v-data-table :headers="headers" :items="stockData" :loading="loading" class="elevation-0">
                                <template v-slot:item.name="{ item }">
                                    <strong>{{ item.name }}</strong>
                                </template>
                                <template v-slot:item.stock="{ item }">
                                    <v-chip color="primary" dark>{{ item.stock }}</v-chip>
                                </template>
                                <template v-slot:item.date="{ item }">
                                    {{ item.date }}
                                </template>
                            </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
                :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
        </div>
    </div>
</template>

<script>
import { ENDPOINTS } from '../../../config'
import { Events } from "../../../plugins/events";
import BaseDateInput from "../../common/BaseDateInput.vue";
import mixin from "../../../mixins/KeyboardMixIns";
import { debounce } from 'lodash';

export default {
    name: "ItemStockHistoryComponent",
    components: {
        BaseDateInput
    },
    mixins: [mixin],
    data() {
        return {
            ENDPOINTS,
            loading: false,
            loadingItems: false,
            items: [],
            stockData: [],
            item: null,
            date: null,
            search: null,
            awaitingSearch: null,
        }
    },
    computed:{
        headers(){
            return [
                {
                    text: this.$t('erp.lang_eanNr'),
                    value: 'ean',
                    align: 'start'
                },
                {
                    text: this.$t('erp.lang_item'),
                    value: 'name',
                    align: 'start'
                },
                {
                    text: this.$t('generic.lang_stockStatus'),
                    value: 'stock',
                    align: 'start'
                },
                {
                    text: this.$t('generic.lang_date'),
                    value: 'date',
                    align: 'start'
                }
            ]
        }
    },
    methods: {
        clearData(){
            this.items = []; 
            this.search=null;
            this.stockData = [];
        },
        itemsFilter(item, queryText) {
            return (
                item.name?.toLowerCase().includes(queryText?.toLowerCase()) ||
                item.ean?.toLowerCase().includes(queryText?.toLowerCase())
            );
        },
        searchItems(val) {
            if (this.loadingItems || (val && val.length < 2) || !val) return;
            this.loadingItems = true;
            clearTimeout(this.awaitingSearch);
            this.awaitingSearch = setTimeout(() => {
                this.items = [];
                this.axios
                    .post(ENDPOINTS.ERP.ITEM.SEARCH2, { query: val })
                    .then((res) => {
                        this.items = [...res.data];
                    })
                    .catch((err) => {

                    })
                    .finally(() => {
                        this.loadingItems = false;
                    });
            }, 1000)
        },
        checkStock() {
            this.loading = true;

            this.axios.post(ENDPOINTS.ERP.ITEM.STOCKHISTORY, {
                itemId: this.item.id,
                date: this.date
            }).then((res) => {
                if(res.data.success){
                    this.stockData = [{
                        ean: this.item.ean,
                        name: this.item.name,
                        stock: res.data.stock,
                        date: this.date
                    }];
                }
            })
                .catch((err) => {

                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
}

</script>