<template>
    <div>
        <page-title :heading="$t('erp.lang_itemStockHistory')" :subheading="$t('erp.lang_itemStockHistory')"
            :icon=icon></page-title>
        <div class="app-main__inner">
            <ItemStockHistoryComponent />
        </div>
    </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import ItemStockHistoryComponent from "../../../components/erp/storage/ItemStockHistoryComponent.vue";

export default {
    components: {
        PageTitle,
        ItemStockHistoryComponent
    },

    data: () => ({
        icon: 'pe-7s-network icon-gradient bg-tempting-azure',
    })
}
</script>